/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
import { blockScreenScroll } from "../utils/screens";

declare global {
  interface Window {
    _edrone: any;
  }
}
const NEWSLETTER_DIALOG_CONFIRMATION = "[data-dialog-newsletter]";
const NEWSLETTER_DIALOG_ERROR = "[data-dialog-newsletter-error]";

const prepareDialog = ($dialog: HTMLDialogElement): void => {
  $dialog.showModal();
  blockScreenScroll();
  $dialog.addEventListener("close", () => {}, { once: true });
};

const showNewsletterConfirm = (): void => {
  const $dialog = document.querySelector<HTMLDialogElement>(NEWSLETTER_DIALOG_CONFIRMATION);
  $dialog && prepareDialog($dialog);
};

const showNewsletterError = (): void => {
  const $dialog = document.querySelector<HTMLDialogElement>(NEWSLETTER_DIALOG_ERROR);
  $dialog && prepareDialog($dialog);
};

const handleNewsletterSub = (): void => {
  const $emailInput = document.querySelector<HTMLInputElement>("[data-newsletter-input-email]");
  const $nameInput = document.querySelector<HTMLInputElement>("[data-newsletter-input-name]");
  const $consentCheck = document.querySelector<HTMLInputElement>("[data-newsletter-input-check='terms-consent']");
  const $processingCheck = document.querySelector<HTMLInputElement>("[data-newsletter-input-check='processing-consent']");

  if ($emailInput && $nameInput && $consentCheck && $processingCheck) {
    const email = String($emailInput.value).trim();
    const name = String($nameInput.value).trim();

    const consent = $consentCheck.checked;
    const processing = $processingCheck.checked;

    if (
      name.length &&
      email.length &&
      consent === true &&
      processing === true &&
      typeof window._edrone !== "undefined" &&
      typeof window._edrone.init === "function"
    ) {
      window._edrone.customer_tags = "Footer Form|ZGODY_PARTNERZY";
      window._edrone.email = email;
      window._edrone.first_name = name;
      window._edrone.action_type = "subscribe";
      window._edrone.init();

      showNewsletterConfirm();
    } else {
      showNewsletterError();
    }
  }
};

/**
 * Exports
 *********************************************************************************************************************/

export const initNewsletterForm = (): void => {
  const $newsletterButton = document.querySelector<HTMLButtonElement>("[data-newsletter]");
  if ($newsletterButton) {
    $newsletterButton.disabled = false;
    $newsletterButton.addEventListener("click", handleNewsletterSub);
  }
};
