/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
import stickybits from "stickybits";
import { throttle } from "../utils/throttle";

const HEADER_ELEMENT = "[data-floating-header]";
const CARD_ELEMENT = "[data-floating-card]";
const DECOR_ELEMENT = "[data-floating-decor]";

export const floatingHeader = (): void => {
  const $headerElement = document.querySelector<HTMLHeadingElement>(HEADER_ELEMENT);
  if ($headerElement) {
    stickybits($headerElement, {
      useStickyClasses: true,
      parentClass: "header-sticky-parent",
      stickyClass: "header-sticky",
      stuckClass: "header-stuck",
      stickyChangeClass: "header-sticky-change",
      useFixed: true,
      stickyBitStickyOffset: 0,
    });
  }
};

const testFloat = () => {
  const $cartElement = document.querySelector<HTMLDivElement>("[data-floating-pannel]");
  if ($cartElement) {
    const card = stickybits($cartElement, {
      useStickyClasses: true,
      parentClass: "card-sticky-parent",
      stickyClass: "card-sticky",
      stuckClass: "card-stuck",
      stickyChangeClass: "card-sticky-change",
      useFixed: false,
      stickyBitStickyOffset: 12,
      verticalPosition: "bottom",
    });
  }
};
testFloat();

const stickProductCard = (): void => {
  const $cartElement = document.querySelector<HTMLDivElement>(CARD_ELEMENT);

  const mql = window.matchMedia("(min-width: 1440px)");

  // TODO: To nie obsługuje resize
  if ($cartElement && mql.matches) {
    const card = stickybits($cartElement, {
      useStickyClasses: true,
      parentClass: "card-sticky-parent",
      stickyClass: "card-sticky",
      stuckClass: "card-stuck",
      stickyChangeClass: "card-sticky-change",
      useFixed: false,
      stickyBitStickyOffset: 0,
    });

    const $cardParent = document.querySelector<HTMLDivElement>("[data-floating-card-parent-observer]");
    if ($cardParent) {
      try {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            card.update();
          }
        });
        resizeObserver.observe($cardParent);
      } catch (error) {
        console.error("ResizeObserver not supported.");
      }
    }
  }
};

export const floatingProductCard = (): void => {
  window.addEventListener("resize", throttle(stickProductCard, 100));
  stickProductCard();
};

export const floatingDecorHub = (): void => {
  const $elements = document.querySelectorAll<HTMLHeadingElement>(DECOR_ELEMENT);

  $elements.forEach(($element) => {
    stickybits($element, {
      useStickyClasses: true,
      parentClass: "decor-sticky-parent",
      stickyClass: "decor-sticky",
      stuckClass: "decor-stuck",
      stickyChangeClass: "decor-sticky-change",
      useFixed: false,
      stickyBitStickyOffset: 200,
    });
  });
};
