/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */

import { setInpostMachine, setPP48Machine, putOpinion, putOpinionImage } from "./js/components/api";
import { blockScreenScroll, allowScreenScroll } from "./js/utils/screens";
import { floatingHeader, floatingProductCard, floatingDecorHub } from "./js/components/floating-stuff";
import { initNewsletterForm } from "./js/components/newsletter";

import { initCartStuff } from "./js/components/cart-managment";
import { initFAQButtons, initExpandButtons, initExpandPromocode, mobileMenuExpanders } from "./js/components/content-expanders";
import IMask from "imask";

declare global {
  interface Window {
    PPWidgetApp: any;
    afterPointSelected: any;
  }
}

floatingHeader();
floatingProductCard();
floatingDecorHub();

initFAQButtons();
initExpandButtons();
initExpandPromocode();
mobileMenuExpanders();

initNewsletterForm();

const closeAllDialogs = () => {
  const $dialogs = document.querySelectorAll<HTMLDialogElement>("[data-dialog]");
  $dialogs.forEach(($dialog) => {
    $dialog.close();
  });
};

const $mobileMenuButton = document.querySelector<HTMLButtonElement>("[data-mobile-menu]");
if ($mobileMenuButton) {
  $mobileMenuButton.disabled = false;
  $mobileMenuButton.addEventListener("click", () => {
    const $mobileMenu = document.querySelector<HTMLDialogElement>("[data-mobile-menu-dialog]");
    if ($mobileMenu) {
      // Show modal
      $mobileMenu.showModal();
      $mobileMenu.classList.add("-translate-x-full");
      //   Block screen
      blockScreenScroll();
      //   On close remoce class once
      $mobileMenu.addEventListener(
        "close",
        () => {
          $mobileMenu.classList.remove("-translate-x-full");
        },
        { once: true }
      );
    }
  });
}

const $mobileLoginButton = document.querySelector<HTMLButtonElement>("[data-mobile-login]");
if ($mobileLoginButton) {
  $mobileLoginButton.disabled = false;
  $mobileLoginButton.addEventListener("click", () => {
    closeAllDialogs();
    const $dialog = document.querySelector<HTMLDialogElement>("[data-login-dialog]");
    if ($dialog) {
      // Show modal
      $dialog.showModal();
      $dialog.classList.add("-translate-x-full");
      //   Block screen
      blockScreenScroll();
      //   On close remoce class once
      $dialog.addEventListener(
        "close",
        () => {
          $dialog.classList.remove("-translate-x-full");
        },
        { once: true }
      );
    }
  });
}

const $dialogs = document.querySelectorAll<HTMLDialogElement>("[data-dialog]");
$dialogs.forEach(($dialog) => {
  $dialog.addEventListener("close", () => {
    allowScreenScroll();
  });
});

initCartStuff();

const expandCartDialogVoucher = ($event) => {
  const $element = $event.currentTarget.parentNode as HTMLDivElement;
  const $expand = $element.querySelector<HTMLDivElement>("[data-voucher-button-expand]");
  const $image = $element.querySelector<HTMLDivElement>("img");
  if ($expand) {
    const state = $expand.ariaHidden === "true";

    if (state === true) {
      $expand.classList.remove("max-h-0");
    }
    if (state === false) {
      $expand.classList.add("max-h-0");
    }

    $expand.ariaHidden = String(!state);

    if ($image) {
      if (state === true) {
        $image.classList.remove("rotate-180");
      }
      if (state === false) {
        $image.classList.add("rotate-180");
      }
    }
  }
};

const $voucherButtons = document.querySelectorAll<HTMLButtonElement>("[data-voucher-button]");
$voucherButtons.forEach(($button) => {
  $button.disabled = false;
  $button.addEventListener("click", expandCartDialogVoucher);
});

const handleCheckboxChange = (event: Event) => {
  if (event.currentTarget !== null) {
    const $el = event.currentTarget as HTMLInputElement;
    const state = $el.checked;
    const elementIndex = $el.getAttribute("data-expander-order");
    const $elementsToExpand = document.querySelectorAll<HTMLDivElement>(`[data-expand-order="${elementIndex}"]`);
    $elementsToExpand.forEach(($element) => {
      $element.setAttribute("data-section-show", state ? "true" : "false");
    });
  }
};

const $orderCheckboxes = document.querySelectorAll<HTMLInputElement>("[data-expander-order]");
$orderCheckboxes.forEach(($singleCheckbox) => {
  $singleCheckbox.addEventListener("input", handleCheckboxChange);
});

const handleDismissAlert = (event: Event) => {
  const $element = event.currentTarget as HTMLButtonElement;
  if ($element) {
    const dismissID = $element.getAttribute("data-dismiss");

    const $dismissElement = document.querySelector(`[data-dismissable='${dismissID}']`);
    if ($dismissElement) {
      $dismissElement.remove();
    }
  }
};
const $dismisableButtons = document.querySelectorAll<HTMLButtonElement>("[data-dismiss]");
$dismisableButtons.forEach(($dismisButton) => {
  $dismisButton.addEventListener("click", handleDismissAlert);
});

const setCheckboxes = (state: boolean): void => {
  const $checkboxElements = document.querySelectorAll<HTMLInputElement>("[data-check]");
  $checkboxElements.forEach(($singleCheckbox) => {
    $singleCheckbox.checked = state;
  });
};

const handleAllCheckboxesInCheckout = (event: Event): void => {
  const $checkboxElement = event.currentTarget as HTMLInputElement;

  if ($checkboxElement) {
    $checkboxElement.checked ? setCheckboxes(true) : setCheckboxes(false);
  }
};

const $checkAllCheckboxes = document.querySelector<HTMLInputElement>("[data-check-all-checkboxes]");
if ($checkAllCheckboxes) {
  $checkAllCheckboxes.addEventListener("change", handleAllCheckboxesInCheckout);
}

const $showMoreOpinionsButton = document.querySelector<HTMLButtonElement>("[data-show-more-opinions]");
if ($showMoreOpinionsButton) {
  $showMoreOpinionsButton.disabled = false;
  $showMoreOpinionsButton.addEventListener("click", (e: Event): void => {
    const $allOpinions = document.querySelectorAll<HTMLDivElement>("[data-single-opinion]");
    $allOpinions.forEach(($singleOpinionContainer) => {
      $singleOpinionContainer.classList.remove("hidden");
    });

    const $clickedButton = e.currentTarget as HTMLButtonElement;
    $clickedButton.disabled = true;
    $clickedButton.classList.add("hidden");
  });
}

const $paczkomatButton = document.querySelector("[data-id='3']");
if ($paczkomatButton) {
  $paczkomatButton.addEventListener("click", () => {
    const $dialog = document.querySelector<HTMLDialogElement>("[data-inpost]");
    if ($dialog) {
      // Show modal
      $dialog.showModal();
      //   Block screen
      blockScreenScroll();
      //   On close remoce class once
      $dialog.addEventListener("close", () => {}, { once: true });
    }
  });
}

window.afterPointSelected = function afterPointSelected(point) {
  setInpostMachine(point);
  const $dialog = document.querySelector<HTMLDialogElement>("[data-inpost]");
  if ($dialog) {
    $dialog.close();
  }
};

const setMasks = () => {
  let $input = document.getElementById("OrderForm_client_phone");
  if ($input) {
    IMask($input, {
      mask: "000000000",
    });
  }

  $input = document.getElementById("Client_client_phone");
  if ($input) {
    IMask($input, {
      mask: "000000000",
    });
  }
  $input = document.getElementById("OrderForm_client_send_phone");
  if ($input) {
    IMask($input, {
      mask: "000000000",
    });
  }

  $input = document.getElementById("OrderForm_client_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }

  $input = document.getElementById("Client_client_send_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }

  $input = document.getElementById("OrderForm_client_send_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }
  $input = document.getElementById("Client_client_post_code");
  if ($input) {
    IMask($input, {
      mask: "00-000",
    });
  }

  $input = document.getElementById("OrderForm_client_nip");
  if ($input) {
    IMask($input, {
      mask: "0000000000",
    });
  }
};
setMasks();

const handleShowMore = (event) => {
  const index = event.currentTarget.getAttribute("data-show-more-button");
  const $el = document.querySelectorAll(`[data-show-more-container='${index}']`);
  $el.forEach((element) => {
    element.classList.remove("max-h-0");
  });
  event.currentTarget.remove();
};
const $ex = document.querySelectorAll("[data-show-more-button]");
$ex.forEach((element) => {
  element.addEventListener("click", handleShowMore);
});

// const favicon = () => {
//   var favicon_images = [
//       "https://t3st.justmeal.com/favicon/favicon-frame-1.gif",
//       "https://t3st.justmeal.com/favicon/favicon-frame-2.gif",
//       "https://t3st.justmeal.com/favicon/favicon-frame-3.gif",
//     ],
//     image_counter = 0; // To keep track of the current image

//   setInterval(function () {
//     // remove current favicon
//     const icon_1 = document.querySelector("link[rel='icon']");
//     if (icon_1) {
//       icon_1.remove();
//     }
//     const icon_2 = document.querySelector("link[rel='shortcut icon']");
//     if (icon_2) {
//       icon_2.remove();
//     }

//     // add new favicon image
//     document
//       .querySelector("head")
//       .insertAdjacentHTML("beforeend", '<link rel="icon" href="' + favicon_images[image_counter] + '" type="image/gif">');

//     // If last image then goto first image
//     // Else go to next image
//     if (image_counter == favicon_images.length - 1) image_counter = 0;
//     else image_counter++;
//   }, 500);
// };
// favicon();

const handleShowSubs = () => {
  closeAllDialogs();
  const $dialog = document.querySelector<HTMLDialogElement>("[data-subs]");
  if ($dialog) {
    $dialog.showModal();
    blockScreenScroll();
  }
};

const showSubs = () => {
  const $subButton = document.querySelector<HTMLButtonElement>("[data-show-subs]");
  if ($subButton) {
    $subButton.disabled = false;
    $subButton.addEventListener("click", handleShowSubs);
  }
};
showSubs();

const getDeliveryAddressForPP48 = () => {
  const r_street = document.querySelector<HTMLInputElement>("input#OrderForm_client_street")?.value;
  const r_building = document.querySelector<HTMLInputElement>("input#OrderForm_client_building")?.value;
  const r_city = document.querySelector<HTMLInputElement>("input#OrderForm_client_city")?.value;
  const r_country = "Polska";

  if (!r_street) {
    return false;
  }
  if (!r_building) {
    return false;
  }
  if (!r_city) {
    return false;
  }
  if (!r_country) {
    return false;
  }

  return {
    street: r_street,
    building: r_building,
    city: r_city,
    country: r_country,
  };
};

const getAlternativeDeliveryAddressForPP48 = () => {
  const r_street = document.querySelector<HTMLInputElement>("input#OrderForm_client_send_street")?.value;
  const r_building = document.querySelector<HTMLInputElement>("input#OrderForm_client_send_building")?.value;
  const r_city = document.querySelector<HTMLInputElement>("input#OrderForm_client_send_city")?.value;
  const r_country = "Polska";

  if (!r_street) {
    return false;
  }
  if (!r_building) {
    return false;
  }
  if (!r_city) {
    return false;
  }
  if (!r_country) {
    return false;
  }

  return {
    street: r_street,
    building: r_building,
    city: r_city,
    country: r_country,
  };
};

const spawnPP48PointWidget = ($element) => {
  if ($element) {
    $element.addEventListener("click", () => {
      let $de = {
        street: "",
        building: "",
        city: "",
        country: "Polska",
      };

      if (getDeliveryAddressForPP48() !== false) {
        $de = getDeliveryAddressForPP48();
      }

      if (getAlternativeDeliveryAddressForPP48() !== false) {
        $de = getAlternativeDeliveryAddressForPP48();
      }

      const address = !$de.street ? "" : [[$de.street, $de.building].join(" "), $de.city, $de.country].join(", ");

      window.PPWidgetApp.toggleMap(
        function (point) {
          setPP48Machine(point);
        },
        false,
        address
      );
    });
  }
};

spawnPP48PointWidget(document.querySelector("[data-id='12']"));

// Opinie -----V

const setScoreRootElementHoverValue = (value, $element) => {
  const $root = $element.closest("[data-hovered-score]");
  if ($root) {
    $root.setAttribute("data-hovered-score", String(value));
  }
};

const setScoreRootElementSelectedValue = (value, $element) => {
  const $root = $element.closest("[data-selected-score]");
  if ($root) {
    $root.setAttribute("data-selected-score", String(value));
  }
};

const handleHoverOutActionForStarOpinion = (event) => {
  const $element = event.currentTarget;
  setScoreRootElementHoverValue(0, $element);
};

const handleHoverActionForStarOpinion = (event) => {
  const $element = event.currentTarget;
  const starScoreValue = parseInt($element.getAttribute("data-star-score"));
  setScoreRootElementHoverValue(starScoreValue, $element);
};

const lightStars = ($root, number) => {
  const $allStars = $root.querySelectorAll("[data-star-score]");
  let counter = 0;
  $allStars.forEach(($el) => {
    if (counter !== number) {
      $el.classList.add("is-highlighted");
      counter += 1;
    }
    if (number === 0) {
      $el.classList.remove("is-highlighted");
    }
  });
};

const handleSetStarOpinion = (event) => {
  const $element = event.currentTarget;
  const starScoreValue = parseInt($element.getAttribute("data-star-score"));
  setScoreRootElementSelectedValue(starScoreValue, $element);
};

const initStarOpinionWidget = () => {
  const $starOpinionButtons = document.querySelectorAll<HTMLButtonElement>("[data-star-score]");
  $starOpinionButtons.forEach(($button) => {
    $button.disabled = false;
    $button.addEventListener("mouseover", handleHoverActionForStarOpinion);
    $button.addEventListener("mouseout", handleHoverOutActionForStarOpinion);
    $button.addEventListener("click", handleSetStarOpinion);
  });

  const mutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type !== "attributes" || mutation.attributeName !== "data-hovered-score") {
        return;
      }
      const $root = mutation.target;
      const hoveredScore = parseInt($root.getAttribute("data-hovered-score"));
      const selectedScore = parseInt($root.getAttribute("data-selected-score"));

      if (selectedScore === 0) {
        lightStars($root, hoveredScore);
      } else {
        lightStars($root, selectedScore);
      }
    }
  };

  const observer = new MutationObserver(mutationCallback);

  const $els = document.querySelectorAll("[data-hovered-score]");
  $els.forEach(($el) => {
    observer.observe($el, { attributes: true });
  });
};

initStarOpinionWidget();

const handleOpinionExpand = (event) => {
  const $button = event.currentTarget;
  const state = $button.getAttribute("data-is-expanded") === "true";
  const index = $button.getAttribute("data-expand-opinion");

  const $section = document.querySelector(`[data-expander-opinion='${index}']`);

  if ($section) {
    $section.setAttribute("data-is-expnd", !state ? "true" : "false");
    $button.setAttribute("data-is-expanded", !state ? "true" : "false");
  }
};
const $expndrButtons = document.querySelectorAll<HTMLButtonElement>("[data-expand-opinion]");
$expndrButtons.forEach(($el) => {
  $el.disabled = false;
  $el.addEventListener("click", handleOpinionExpand);
});

// const grabPayloadFromForm = async (formIndex) => {
// const $form = document.querySelector(`[data-opinion-form='${formIndex}']`);
// if ($form) {
//   const $photosInputs = $form.querySelectorAll<HTMLInputElement>("input[type='file']");
//   if ($photosInputs) {
//     const fileInputs = Array.from($photosInputs);
//     const filesToUpload = fileInputs.map(($input) => {
//       return {
//         field: $input.getAttribute("data-for-field"),
//         file: $input.files ? $input.files[0] : null,
//       };
//     });
//     Promise.all(
//       filesToUpload.map(async (file) => {
//         if (file.file) {
//           const ajaxData = new FormData();
//           ajaxData.append("file", file.file);
//           ajaxData.append("field", file.field);
//           return await putOpinionImage(ajaxData);
//         } else {
//           return {
//             status: 500,
//           };
//         }
//       })
//     ).then((values) => {
//       values.forEach((response) => {
//         if (response.status === 200) {
//           const $hiddenInput = $form.querySelector(`input[name='${response.field}']`);
//           $hiddenInput.value = response.payload.nazwa;
//         }
//       });
//     });
//   }
//   return setTimeout(() => {

//     let photo_additional_1 = "";
//     let photo_additional_2 = "";
//     let photo_additional_3 = "";

//     return {

//     };
//   }, 0);
// }
// return {};
// };

type OpinionImageResponse = {
  status: 200 | 500;
  field?: string;
  payload?: {
    tryb: string;
    wysokosc: string;
    szerokosc: string;
    nazwa: string;
    katalog: string;
  };
};

const handleFormPreSubmit = ($form: HTMLElement) => {
  return new Promise((resolve, reject) => {
    const $photosInputs = $form.querySelectorAll<HTMLInputElement>("input[type='file']");
    if ($photosInputs.length) {
      const fileInputs = Array.from($photosInputs);
      const filesToUpload = fileInputs.map(($input) => {
        return {
          field: $input.getAttribute("data-for-field"),
          file: $input.files ? $input.files[0] : null,
        };
      });
      return Promise.all(
        filesToUpload.map(async (file): Promise<OpinionImageResponse> => {
          if (file.file && file.field) {
            const ajaxData = new FormData();
            ajaxData.append("file", file.file);
            ajaxData.append("field", file.field);
            return await putOpinionImage(ajaxData);
          } else {
            return {
              status: 500,
            };
          }
        })
      )
        .then((values: OpinionImageResponse[]) => {
          values.forEach((singleAction: OpinionImageResponse) => {
            if (singleAction.status === 200) {
              const $hiddenInput = $form.querySelector<HTMLInputElement>(`input[name='${singleAction.field}']`);
              if ($hiddenInput && singleAction.payload) {
                $hiddenInput.value = String(singleAction.payload.nazwa || "");
              }
            }
          });
        })
        .finally(() => {
          const productId = $form.querySelector<HTMLInputElement>("input[name='product-id']")?.value || "";
          const clientId = $form.querySelector<HTMLInputElement>("input[name='client-id']")?.value || "";
          const orderId = $form.querySelector<HTMLInputElement>("input[name='order-id']")?.value || "";
          const desc = $form.querySelector<HTMLTextAreaElement>("textarea[name='opinion-textarea']")?.value || "";
          const score = $form.querySelector<HTMLDivElement>("[data-selected-score]")?.getAttribute("data-selected-score");

          const photo_additional_1 = $form.querySelector<HTMLInputElement>("input[name='photo_additional_1']")?.value || "";
          const photo_additional_2 = $form.querySelector<HTMLInputElement>("input[name='photo_additional_2']")?.value || "";
          const photo_additional_3 = $form.querySelector<HTMLInputElement>("input[name='photo_additional_3']")?.value || "";

          return resolve({
            clientId,
            productId,
            orderId,
            score,
            desc,
            photo_additional_1,
            photo_additional_2,
            photo_additional_3,
          });
        });
    }
    reject(new Error("Something went wrong"));
  });
};

const handleFormSubmit = (event: Event): void => {
  (async function () {
    const $button = event.currentTarget as HTMLButtonElement;
    const formIndex = $button.getAttribute("data-send-opinion");
    const $form = document.querySelector<HTMLElement>(`[data-opinion-form='${formIndex}']`);
    if ($form) {
      const payload = await handleFormPreSubmit($form);
      putOpinion(payload).then((res) => {
        if (res.status === 201) {
          window.location.reload();
        }
      });
    }
  })();
};

const $submitButtons = document.querySelectorAll<HTMLButtonElement>("[data-send-opinion]");
$submitButtons.forEach(($el) => {
  $el.disabled = false;
  $el.addEventListener("click", handleFormSubmit);
});

const checkIfDragAndDrop = () => {
  const div = document.createElement("div");
  return ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) && "FormData" in window && "FileReader" in window;
};

if (checkIfDragAndDrop()) {
  const MIME_TYPE_WHITELIST = ["image/png", "image/jpeg", "image/jpg"];

  const preventDefaultActions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragAndDropActionStart = (e) => {
    const $form = e.currentTarget;
    $form.classList.add("bg-gray");
  };

  const onDragAndDropActionEnd = (e) => {
    const $form = e.currentTarget;
    $form.classList.remove("bg-gray");
  };

  const checkOpinionFileMimeType = (file): boolean => MIME_TYPE_WHITELIST.includes(file.type);

  const showImagePreview = ($form: HTMLFormElement, file) => {
    const $preview = $form.querySelector("[data-uploaded-preview]");
    if ($preview) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = document.createElement("img");
        img.src = String(reader.result);
        $preview.innerHTML = "";
        $preview.appendChild(img);
      };
    }
  };

  const handleFileDrop = (e: DragEvent) => {
    const $form = e.currentTarget as HTMLFormElement;
    if (e.dataTransfer) {
      const droppedFile = e.dataTransfer.files[0];
      if (checkOpinionFileMimeType(droppedFile)) {
        showImagePreview($form, droppedFile);
      }
    }
  };

  const handleFileSelect = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const $form = e.currentTarget as HTMLFormElement;
    const droppedFile = target.files ? target.files[0] : null;
    if (droppedFile && checkOpinionFileMimeType(droppedFile)) {
      showImagePreview($form, droppedFile);
    }
  };

  const $imageDrops = document.querySelectorAll<HTMLFormElement>("[data-image-drop]");
  $imageDrops.forEach(($imageDrop) => {
    ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach((eventName) => {
      $imageDrop.addEventListener(eventName, preventDefaultActions, false);
    });

    ["dragover", "dragenter"].forEach((eventName) => {
      $imageDrop.addEventListener(eventName, onDragAndDropActionStart, false);
    });

    ["dragleave", "dragend", "drop"].forEach((eventName) => {
      $imageDrop.addEventListener(eventName, onDragAndDropActionEnd, false);
    });

    $imageDrop.addEventListener("drop", handleFileDrop, false);
    $imageDrop.addEventListener("change", handleFileSelect, false);
  });
}
