/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */
const FAQ_BUTTON_ELEMENT = "[data-faq-button]";
const EXPAND_SECTION_ELEMENT = "[data-tab-expand]";
const EXPANDER_BUTTON_ELEMENT = "[data-tab-expander]";

const expandFAQ = (e: Event): void => {
  const $currentTarget = e.currentTarget as HTMLButtonElement;
  const $root = $currentTarget.parentNode as HTMLDivElement;

  const $elementToExpand = $root.querySelector<HTMLDivElement>("[data-faq-button-expand]");

  if ($elementToExpand) {
    const state = $elementToExpand.ariaHidden === "true";
    if (state) {
      $elementToExpand.classList.remove("max-h-0");
      $elementToExpand.classList.remove("h-0");
      $currentTarget.setAttribute("aria-selected", "true");
    } else {
      $elementToExpand.classList.add("max-h-0");
      $elementToExpand.classList.add("h-0");
      $currentTarget.setAttribute("aria-selected", "false");
    }
    $elementToExpand.ariaHidden = String(!state);
  }
};

const handleContentExpandButtons = ($currentTarget: HTMLElement): void => {
  const $expandButtons = document.querySelectorAll(EXPANDER_BUTTON_ELEMENT);
  $expandButtons.forEach(($singleButton) => {
    $singleButton.setAttribute("aria-selected", "false");

    if ($singleButton === $currentTarget) {
      $singleButton.setAttribute("aria-selected", "true");
    }
  });
};

const handleContentExpanders = (index: string): void => {
  const $expanders = document.querySelectorAll(EXPAND_SECTION_ELEMENT);
  $expanders.forEach(($expandSection) => {
    $expandSection.setAttribute("aria-hidden", "true");
    $expandSection.classList.add("max-h-0");
    $expandSection.classList.add("h-0");

    if ($expandSection.getAttribute("data-tab-expand") === index) {
      $expandSection.setAttribute("aria-hidden", "false");
      $expandSection.classList.remove("max-h-0");
      $expandSection.classList.remove("h-0");
    }
  });
};

const expandContent = (e: Event): void => {
  const $currentTarget = e.currentTarget as HTMLButtonElement;
  const index = $currentTarget.getAttribute("data-tab-expander") || "";

  handleContentExpandButtons($currentTarget);
  handleContentExpanders(index);
};

const foldContent = (): void => {
  const $expanders = document.querySelectorAll(EXPAND_SECTION_ELEMENT);
  $expanders.forEach(($expandSection) => {
    $expandSection.setAttribute("aria-hidden", "true");
    $expandSection.classList.add("max-h-0");
    $expandSection.classList.add("h-0");
  });
  const $mobileButtons = document.querySelectorAll(`[data-tab-mobie-expander]`);
  $mobileButtons.forEach(($expandSection) => {
    $expandSection.setAttribute("aria-selected", "false");
  });
};

const expandMobile = (e: Event): void => {
  const $currentTarget = e.currentTarget as HTMLButtonElement;
  const index = $currentTarget.getAttribute("data-tab-mobie-expander") || "";

  const $expanders = document.querySelectorAll(EXPAND_SECTION_ELEMENT);
  $expanders.forEach(($expandSection) => {
    $expandSection.setAttribute("aria-hidden", "true");
    $expandSection.classList.add("max-h-0");
    $expandSection.classList.add("h-0");

    if ($expandSection.getAttribute("data-tab-expand") === index) {
      $expandSection.setAttribute("aria-hidden", "false");
      $expandSection.classList.remove("max-h-0");
      $expandSection.classList.remove("h-0");
    }
  });

  const $mobileExpandButtons = document.querySelectorAll<HTMLButtonElement>("[data-tab-mobie-expander]");
  $mobileExpandButtons.forEach(($singleButton) => {
    $singleButton.setAttribute("aria-selected", "false");
  });

  $currentTarget.setAttribute("aria-selected", "true");

  const $scrollElement = document.querySelector(`[data-tab-folder='${index}']`);
  if ($scrollElement) {
    $scrollElement.scrollIntoView();
  }
};

const handleExpandOfPromocodes = (event: Event): void => {
  const $button = event.currentTarget as HTMLButtonElement;
  const $root = $button.parentNode;
  if ($root) {
    const $sectiontoExlpand = $root.querySelector<HTMLDivElement>("[data-expand-promocode]");
    const $sectionChevron = $root.querySelector<HTMLImageElement>("[data-chevron-state]");

    if ($sectiontoExlpand) {
      const currentState = $sectiontoExlpand.ariaHidden === "true";
      $sectiontoExlpand.ariaHidden = String(!currentState);

      if ($sectionChevron) {
        const currentChevronState = $sectionChevron.getAttribute("data-chevron-state") === "true";
        $sectionChevron.setAttribute("data-chevron-state", String(!currentChevronState));
      }
    }
  }
};

const handleMobileExpander = (event: Event): void => {
  const $button = event.currentTarget as HTMLButtonElement;
  const index = $button.getAttribute("data-mobile-menu-expand") || "";
  const $sectionToExpand = document.querySelector<HTMLDivElement>(`[data-mobile-menu-expander='${index}']`);
  if ($sectionToExpand) {
    const state = $button.getAttribute("aria-selected") === "true";

    if (state === false) {
      $sectionToExpand.classList.remove("max-h-0");
      $sectionToExpand.classList.remove("h-0");
      $button.setAttribute("aria-hidden", "false");
    }
    if (state === true) {
      $sectionToExpand.classList.add("max-h-0");
      $sectionToExpand.classList.add("h-0");
      $button.setAttribute("aria-hidden", "true");
    }

    $button.setAttribute("aria-selected", String(!state));
  }
};

/**
 * Exports
 *********************************************************************************************************************/

export const initFAQButtons = () => {
  const $buttons = document.querySelectorAll<HTMLButtonElement>(FAQ_BUTTON_ELEMENT);
  $buttons.forEach(($singleButton) => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", expandFAQ);
  });
};

export const initExpandButtons = () => {
  const $buttons = document.querySelectorAll<HTMLButtonElement>(EXPANDER_BUTTON_ELEMENT);
  $buttons.forEach(($singleButton) => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", expandContent);
  });

  const $foldButtons = document.querySelectorAll<HTMLButtonElement>("[data-tab-folder]");
  $foldButtons.forEach(($singleButton) => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", foldContent);
  });

  const $mobileExpandButtons = document.querySelectorAll<HTMLButtonElement>("[data-tab-mobie-expander]");
  $mobileExpandButtons.forEach(($singleButton) => {
    $singleButton.disabled = false;
    $singleButton.addEventListener("click", expandMobile);
  });
};

export const initExpandPromocode = (): void => {
  const $expandButtons = document.querySelectorAll<HTMLButtonElement>("[data-expander-promocode]");

  $expandButtons.forEach(($singleElement) => {
    $singleElement.disabled = false;
    $singleElement.addEventListener("click", handleExpandOfPromocodes);
  });
};

export const mobileMenuExpanders = (): void => {
  const $expandButtons = document.querySelectorAll<HTMLButtonElement>("[data-mobile-menu-expand]");

  $expandButtons.forEach(($singleElement) => {
    $singleElement.disabled = false;
    $singleElement.addEventListener("click", handleMobileExpander);
  });
};
