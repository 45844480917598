/**
 * Budowanie paczki:
 *  package.json
 *  npm install
 *  npm run build/watch
 */

/**
 *  Block vertical scroll.
 */
export const blockScreenScroll = (): void => {
  document.body.classList.add("overflow-hidden");
};

/**
 *  Allow vertical scroll.
 */
export const allowScreenScroll = (): void => {
  document.body.classList.remove("overflow-hidden");
};
